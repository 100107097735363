.h1 {
  @apply font-bold leading-[42px] font-noto text-[33px] break-all;
}

.h2 {
  @apply font-bold leading-8 font-noto text-[22px] break-all;
}

.h3 {
  @apply text-sm font-bold leading-5 break-all font-noto;
}

.h4 {
  @apply text-xs font-bold leading-4 break-all font-noto;
}

.body {
  @apply text-sm font-medium leading-5 break-all font-noto;
}

.body2 {
  @apply font-medium leading-4 font-noto text-[12px]  break-all;
}

.caption {
  @apply font-medium leading-3 font-noto text-[10px]  break-all;
}
